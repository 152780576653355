import {createRouter, createWebHistory} from 'vue-router'

const routes = [

    {
        path: "/front",
        name: "Front",
        component: () => import('../views/FrontView.vue'),
        children: [
            {
                path: 'user',
                name: 'User',
                component: () => import('../views/UserView.vue')
            },
            {
                path: 'device',
                name: 'Device',
                component: () => import('../views/DeviceView.vue')
            },
            {
                path: 'cnc',
                name: 'CNC',
                component: () => import('../views/CNCStatusView.vue')
            },
            {
                path: 'che',
                name: 'CHE',
                component: () => import('../views/CheStatusView.vue')
            },
            {
                path:'add',
                name: 'Add',
                component:()=>import('../views/AddView.vue')

            },
            {
                path:'update',
                name: 'Update',
                component:()=>import('../views/updateView.vue')
            },

            {
                path: 'capacity',
                name: 'Capacity',
                component: () => import('../views/CapacityView')
            },
            {
                path: 'manage',
                name: 'Manage',
                component:()=>import('../views/manageView.vue')
            }

        ]
    },
    {
        path: '',
        redirect: {name: "Manage"},
    },

]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router

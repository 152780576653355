<template>
    <el-container>
        <el-header height="72px" style="border-bottom: 1px solid #f5f5f5;">
            <div class="pg-header">
                <div class="logo">
                    <img src="./assets/logo.png">
                </div>
                <div class="top-menu">
                    <h1>西安零特精密机械有限责任公司</h1>
                </div>
            </div>
        </el-header>
        <router-view/>
    </el-container>
</template>

<script setup>

</script>

<style>
    body {
        margin: 0;
    }

    img {
        height: 100%;
    }

    .pg-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        height: 72px;
    }

    .pg-header .logo {
        height: 48px;
    }

    .pg-header .top-menu a {
        padding: 0 5px;
        text-decoration: none;
    }
</style>

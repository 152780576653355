"use strict";

import axios from "axios";
import address from "@/utils/address";

// axios.defaults.baseURL = "http://127.0.0.1:8000/";
axios.defaults.baseURL=address.baseURL
let config = {

};

const _axios = axios.create(config);

_axios.interceptors.request.use(
    function (config) {
        // Do something before request is sent
        // const token = localStorage.getItem("token");
        // if (token) {
        //     config.headers.common['Authorization'] = token;
        // }
        return config;
    }
);

// Add a response interceptor
_axios.interceptors.response.use(
    function (response) {
        // Do something with response data
        return response;
    },
    function (error) {
        // Do something with response error
        console.log(error.response.status);
        // if (error.response.status === 401) {
        //     store.commit("logout");
        //     router.replace({name: "Login"});
        //     ElMessage.error("认证失败，请重新登录");
        // }
        return Promise.reject(error);
    }
);

export default _axios;

import { createApp } from 'vue'
import App from './App.vue'
import './plugins/axios'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import _axios from './plugins/axios'
import * as echarts from 'echarts'

const app=createApp(App)
app.config.globalProperties.$axios=_axios;
// app.config.globalProperties.$echarts=echarts;
app.use(ElementPlus,{locale:zhCn}).use(router).mount('#app')



const env=process.env.NODE_ENV


const config={
    development:{
        baseURL:'http://127.0.0.1:8000'
    },
    production:{
        baseURL:'https://www.17ok.cn/'
    }
}
export default {
    baseURL:config[env].baseURL
}
